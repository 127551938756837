import Chip from '@mui/material/Chip';
import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { AccordionWrapper, CoccaAccordionSummary, CoccaAccordionDetails, CoccaAccordion } from './Accordions';
import { colors } from '../../settings/colors';

const Notice = ({ data, expanded, panel, handleChange }) => {
  return (
    <CoccaAccordion expanded={expanded === panel} onChange={handleChange(panel)} key={data.title}>
      <CoccaAccordionSummary sx={{ backgroundColor: colors.lightGrey }} className='rdap-notice-summary'>
        <Typography>{data.title}</Typography>
      </CoccaAccordionSummary>
      <CoccaAccordionDetails className='rdap-notice-details'>
        <Typography sx={{ textAlign: 'left', mb: 2 }}>{data?.description?.join(' ')}</Typography>
        <Stack>
          {data.links?.map((link) => (
            <Chip
              key={link.title}
              label={link.title}
              href={link.href}
              component="a"
              target="_blank"
              variant="outlined"
              clickable
            />
          ))}
        </Stack>
      </CoccaAccordionDetails>
    </CoccaAccordion>
  );
};

export default function CustomizedAccordions({ data }) {
  const [expanded, setExpanded] = React.useState('panel');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (!data) {
    return null;
  }

  return (
    <AccordionWrapper title={'Notices'}>
      {data.map((notice, index) => (
        <Notice key={notice.title} data={notice} expanded={expanded} panel={`panel${index}`} handleChange={handleChange} />
      ))}
    </AccordionWrapper>
  );
}