import * as React from 'react';
import { Route, Routes } from "react-router-dom";

import './App.css';
import Home from "./pages/home";
import Search from './pages/search';
import NavBar from './components/NavBar';
import Resource from './pages/resource';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/search' element={<Search />} />
        <Route exact path='/resource' element={<Resource />} />
      </Routes>
    </div>
  );
}

export default App;
