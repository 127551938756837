
export function deduplicateArray(array) {
  if (!array || array.length === 0) {
    return [];
  }

  return [...new Set(array)];
}

export function deduplicateArrayToString(array) {
  if (!array || array.length === 0) {
    return '';
  }

  // Also removes empty element
  const nonEmptyArray = array.filter(element => !!element);

  return deduplicateArray(nonEmptyArray).join(', ');
}

export function capitaliseFirstLetter(str) {
  return str ? `${str.charAt(0).toUpperCase() + str.slice(1)}` : '';
}

export const getBaseApiUrl = () => {
  return window.Qh0PGZODUV ?? 'https://rdap.coccaregistry.org/';
}

export const getExactMatchFlag = () => {
  return window.Eh0PGX1DUV ?? false;
}

export const getLogoUrl = () => {
  let BASE_URL = getBaseApiUrl();
  return `${BASE_URL}logo`;

}

export const getDescriptionText = () => {
  return window.wcWUhi83mj ?? 'CoCCA has migrated to Registration Data Access Protocal (RDAP) that is designed to address a number of shorting comings with WHOIS service.';;
}