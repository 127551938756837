import Grid from '@mui/material/Unstable_Grid2';
import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { useForm, FormProvider } from 'react-hook-form';

import SearchField from '../components/SearchField';
import { searchFilterAtom } from '../atoms/searchAtoms';

function SearchPanel({ onSubmitFunc }) {
  const [searchFilter,] = useAtom(searchFilterAtom);
  const [searchFilterData,] = useState(searchFilter);
  const form = useForm({ defaultValues: searchFilterData });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmitFunc)}>
        <Grid container justifyContent="center" spacing={0}>
          <Grid xs={12} sx={{ display: 'flex', m: 'auto' }}>
            <SearchField 
              id={'keyword'} 
              label={''} 
              props={{ 
                rules: { 
                  required: true
                },
                inputStyles: {
                  borderRight: 'none'
                }
              }} 
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

export default SearchPanel;
