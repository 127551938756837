import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Entry = ({ label, value, props = {} }) => {
  const labelStyles = props.labelStyles ?? {};

  return (
    <Stack direction={'row'} className='rdap-entry-wrapper'>
      <Typography gutterBottom component="div" sx={{ fontSize: '16px', fontWeight: 'bold', ...labelStyles }} className='rdap-entry-label'>
        {label}
      </Typography>

      {typeof value === 'string' &&
        <Typography sx={{ ml: 3 }} className='rdap-entry-value'>
          {value ?? ''}
        </Typography>
      }

      {typeof value !== 'string' &&
        value
      }
    </Stack>
  );
};

export default Entry;