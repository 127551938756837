import { FormControl, MenuItem, Typography, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useAtom } from 'jotai';

import { formInputFieldStyle } from './DataTypes';
import { searchTypeAtom } from '../atoms/searchAtoms';

// Should only be used for search bar
const FormSelect = ({ id, label, options, showExternalLabel = false, props = {} }) => {
  const { control } = useFormContext();
  const [, setSearchFilter] = useAtom(searchTypeAtom);

  return (
    <FormControl fullWidth variant='standard'>
      { showExternalLabel && <Typography variant='body1'>{label}</Typography> }
      <Controller
        name={id}
        control={control}
        defaultValue={''}
        rules={props}
        render={({ field }) => {
          return (
            <TextField
              size='small'
              select
              label={label}
              value={field.value}
              onChange={(newType) => {
                field.onChange(newType);
                setSearchFilter(newType.target.value);
              }}
              variant='outlined'
              sx={formInputFieldStyle}
            >
              {options.map((option) => {
                return (
                  <MenuItem key={option.key} value={option.value}>
                    {option.key}
                  </MenuItem>
                );
              })}
            </TextField>
          );
        }}
      />
    </FormControl>
  );
};

export default FormSelect;
