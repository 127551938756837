import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { colors } from '../../settings/colors';
import { CoccaAccordion, CoccaAccordionDetails, CoccaAccordionSummary, AccordionWrapper } from './Accordions';
import Entry from './Entry';

function getIpVersion(ipAddresses, shouldShow) {
  if (ipAddresses?.v4 && shouldShow) {
    return `(${ipAddresses.v4})`;
  } else if (ipAddresses?.v6 && shouldShow) {
    return `(${ipAddresses.v6})`;
  } else {
    return '';
  }
}

const Nameserver = ({ nameserver, expanded, panel, handleChange }) => {
  const [showRawData, setShowRawData] = useState(false);

  const toggleRawData = () => {
    setShowRawData(!showRawData);
  };
  
  return (
    <CoccaAccordion className='rdap-nameserver-wrapper' expanded={expanded} onChange={handleChange(panel)}>
      <CoccaAccordionSummary sx={{ backgroundColor: colors.lightGrey }} selected={expanded === panel}>
        <Typography>{`${nameserver.ldhName} ${getIpVersion(nameserver.ipAddresses, expanded !== panel)}`}</Typography>
      </CoccaAccordionSummary>
      <CoccaAccordionDetails>
        <Entry label={'Object class name:'} value={nameserver.objectClassName} props={{ labelStyles: { fontWeight: 'bold' } }} />
        <Entry label={'LDH Name:'} value={nameserver.ldhName} props={{ labelStyles: { fontWeight: 'bold' } }} />
        <Entry label={'Unicode Name:'} value={nameserver.unicodeName} props={{ labelStyles: { fontWeight: 'bold' } }} />
        <Entry label={'Status:'} value={
          <Stack direction={'row'} spacing={2} sx={{ ml: 1 }}>
            {nameserver?.status?.map((s) => (
              <Chip 
                key={s} 
                label={s} 
                size='small' 
                sx={{ ml: 1, backgroundColor: colors.lightGreen, color: colors.white }} 
                className='rdap-nameserver-status'
              />
            ))}
          </Stack>
        }  props={{ labelStyles: { fontWeight: 'bold' } }} />
        {nameserver?.ipAddresses?.v4 && <Entry label={'IPv4:'} value={nameserver.ipAddresses.v4.join(', ')} props={{ labelStyles: { fontWeight: 'bold' } }} />}
        {nameserver?.ipAddresses?.v6 && <Entry label={'IPv6:'} value={nameserver.ipAddresses.v6.join(', ')} props={{ labelStyles: { fontWeight: 'bold' } }} />}
        <Entry label={'Link:'} value={
          <Chip
            key={`link-${panel}`}
            label={nameserver.ldhName}
            href={`/resource?keyword=${nameserver.ldhName}&type=nameservers`}
            component="a"
            target="_blank"
            variant="outlined"
            clickable
            className='rdap-nameserver-link'
          />
        }  props={{ labelStyles: { mt: '4px', mr: '21px', mb: 2 } }} />
        <Box sx={{ textAlign: 'left' }}>
          <Button className='rdap-show-raw-data-button' variant="contained" onClick={toggleRawData}>
            {`${showRawData ? 'Hide' : 'Show'} Raw Data`}
          </Button>
          {showRawData && (
            <pre>{JSON.stringify(nameserver, null, 2)}</pre>
          )}
        </Box>
      </CoccaAccordionDetails>
    </CoccaAccordion>
  );
};

export default function Nameservers({ data }) {
  const [expanded, setExpanded] = React.useState(data?.map(d => false));

  const handleChanged = (panel) => (event, isExpanded) => {
    const newExpanded = [...expanded];

    newExpanded[panel] = isExpanded ? true : false;

    setExpanded(newExpanded);
  };

  if (!data) {
    return null;
  }
  
  return (
    <AccordionWrapper title='Nameservers'>
      {data.map((element, index) => (
        <Nameserver
          key={`${element.ldhName}-${index}`} 
          nameserver={element} 
          expanded={expanded[index]} 
          panel={index} 
          handleChange={handleChanged} 
        />
      ))}
    </AccordionWrapper>
  );
}