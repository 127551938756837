import { getBaseApiUrl, getExactMatchFlag } from '../settings/utils';

const BASE_URL = getBaseApiUrl();

function getUrl(payload, pageNumber, pageSize) {
  const type = payload.type;
  const keyword = payload.keyword;
  const exactMatch = getExactMatchFlag();

  if (type === 'domains' || type === 'nameservers') {
    return `${BASE_URL}${type}?name=${keyword}&exactMatch=${exactMatch}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  } else {
    return `${BASE_URL}entity/${keyword}?exactMatch=${exactMatch}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }
}

function getSearchResultsType(type) {
  let result = null;

  switch (type) {
    case 'domains':
      result = 'domainSearchResults';
      break;
    case 'nameservers':
      result = 'nameserverSearchResults';
      break;
    case 'entity':
    case 'registrar':
    default:
      break;
  }

  return result;
}

function getResourceType(type) {
  let result = 'entity';

  switch (type) {
    case 'domains':
      result = 'domain';
      break;
    case 'nameservers':
      result = 'nameserver';
      break;
    case 'entity':
    case 'registrar':
    default:
      break;
  }

  return result;
}

export const getAsync = async (keyword, type) => {
  const resourceType = getResourceType(type);
  const url = `${BASE_URL}${resourceType}/${keyword}`;
  
  try {
    const response = await fetch(url);

    if (!response.ok) {
      if (response.status === 404) {
        return null;
      }

      throw new Error('Request failed');
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Error fetching data:', error.message);

    return null;
  }
};


export const searchAsync = async (payload, pageNumber = 1, pageSize = 10) => {
  const url = getUrl(payload, pageNumber, pageSize);
  const type = getSearchResultsType(payload.type);
  
  try {
    const response = await fetch(url);

    if (!response.ok) {
      if (response.status === 404) {
        return [];
      }

      throw new Error('Request failed');
    }

    const data = await response.json();
    
    return {
      results: type ? data[type] : [data], // Only domain and NameServer supports search, entity and registrar are get.
      pagination: data.paging_metadata
    };
  } catch (error) {
    console.error('Error searching data:', error.message);

    return {
      results: [], // Only domain and NameServer supports search, entity and registrar are get.
      pagination: {
        pageNumber: 1,
        pageSize: 10,
        totalCount: 0
      }
    };
  }
};