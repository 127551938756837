import { Chip, Stack, Box } from '@mui/material';
import React from 'react';

import { AccordionWrapper } from './Accordions';
import { colors } from '../../settings/colors';
import Entry from './Entry';

function getValue(value) {
  if (value === undefined) {
    return '';
  }

  return value ? 'Yes' : 'No';
}

const Details = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <AccordionWrapper title={'Details'}>
      <Entry label={'Object class name:'} value={data.objectClassName} />
        <Entry label={'Handle:'} value={data.handle} />
        { data.ldhName && <Entry label={'LDH name:'} value={data.ldhName} />}
        { data.unicodeName && <Entry label={'Unicode name:'} value={data.unicodeName} /> }
        { getValue(data?.secureDNS?.delegationSigned) && <Entry label={'Secure DNS delegation signed:'} value={getValue(data?.secureDNS?.delegationSigned)} /> }
        { data?.status && 
          <Entry label={'Status:'} value={
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', rowGap: 2, ml: '5px!important' }}>
              {data?.status?.map((s) => (
                <Chip 
                  key={s} 
                  label={s} 
                  size='small' 
                  sx={{ ml: 1, backgroundColor: colors.lightGreen, color: colors.white }} 
                />
              ))}
            </Box>
          } />}
        {data?.ipAddresses?.v4 && <Entry label={'IPv4:'} value={data.ipAddresses.v4.join(', ')} props={{ labelStyles: { fontWeight: 'bold' } }} />}
        {data?.ipAddresses?.v6 && <Entry label={'IPv6:'} value={data.ipAddresses.v6.join(', ')} props={{ labelStyles: { fontWeight: 'bold' } }} />}
        {data?.roles && <Entry label={'Roles:'} value={
          <Stack direction={'row'} spacing={2} sx={{ ml: 1 }}>
            {data?.roles?.map((role) => (
              <Chip 
                key={role} 
                label={role} 
                size='small' 
                sx={{ ml: 1, backgroundColor: colors.lightGreen, color: colors.white }} 
              />
            ))}
          </Stack>
        } />}
        <Stack sx={{ mt: 1 }}>
          {data?.links?.map((link) => (
            <Chip
              key={link.title}
              label={link.href}
              href={link.href}
              component="a"
              target="_blank"
              variant="outlined"
              clickable
            />
          ))}
        </Stack>
    </AccordionWrapper>
  );
};

export default Details;
