import { AccordionWrapper } from './Accordions';
import { deduplicateArrayToString } from '../../settings/utils';
import Entry from './Entry';
import { Fragment } from 'react';

const Variants = ({ data }) => {
  if (!data) {
    return null;
  }
  
  return (
    <AccordionWrapper title={'Variants'}>
      {data.map((variant, vIndex) => (
        variant?.variantNames?.map((variantName, vnIndex) => (
          <Fragment>
            <Entry 
              key={`ldhName-${variantName.ldhName}-${vIndex}-${vnIndex}`} 
              label={'LDH Name:'}
              value={variantName.ldhName}
            />
            <Entry 
              key={`unicodeName-${variantName.unicodeName}-${vIndex}-${vnIndex}`} 
              label={'Unicode Name:'}
              value={variantName.unicodeName}
            />
            <Entry 
              key={`relations-${vIndex}-${vnIndex}`} 
              label={'Relations:'}
              value={deduplicateArrayToString(variant.relations)}
            />
          </Fragment>
        ))
      ))}
    </AccordionWrapper>
  );
};

export default Variants;