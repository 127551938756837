import { atom } from "jotai";

import { getBaseApiUrl, getExactMatchFlag } from '../settings/utils';

//const BASE_URL = window.bf2f073466962b1 || 'https://rdap.coccaregistry.org/';
const BASE_URL = getBaseApiUrl();

// this file may not in use 

function getUrl(payload) {
  const type = payload.type;
  const keyword = payload.keyword;
  if (type === 'domains' || type === 'nameservers') {
    return `${BASE_URL}${type}?name=${keyword}&exactMatch=${getExactMatchFlag()}`;
  } else {
    return `${BASE_URL}entity/${keyword}`;
  }
}

export const searchFilterAtom = atom({
  type: 'domains',
  keyword: ''
});

const searchResultsAtom = atom([]);
export const handleSearchAtom = atom(
  (get) => get(searchResultsAtom),
  async (get, set, payload) => {
    const url = getUrl(payload);
    const type = payload.type === 'domains'
      ? 'domainSearchResults'
      : 'nameserverSearchResults';
    const response = await fetch(url);

    if (!response.ok) {
      set(searchResultsAtom, []);
    }

    const data = await response.json();
    
    set(searchResultsAtom, data[type]);
  }
);

export const searchTypeAtom = atom('domains');

// https://codesandbox.io/s/jotai-async-get-nnzvp?file=/src/App.js
// https://tutorial.jotai.org/quick-start/write-only-atoms
