import CircularProgress from '@mui/material/CircularProgress';
import { Container, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom';

import SearchPanel from '../components/SearchPanel';
import { searchFilterAtom } from '../atoms/searchAtoms';
import { searchAsync } from '../atoms/searchEventHandler';
import { getLogoUrl } from '../settings/utils';
import { getDescriptionText } from '../settings/utils';

const DESCRIPTION_TEXT = getDescriptionText();
const LOGO_URL = getLogoUrl();

function App() {
  const [, setSearchFilter] = useAtom(searchFilterAtom);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  async function getSearchResults(param) {
    setIsLoading(true);

    const searchResults = await searchAsync(param);
    const results = searchResults.results;
    const pagination = searchResults.pagination;

    setIsLoading(false);

    if (results && results.length === 1) {
      const result = results[0];
      const key = result.ldhName ?? result.handle;

      navigate(`/resource?keyword=${key}&type=${param.type}&bth=true`);
    } else {
      navigate(`/search?pageNumber=${pagination?.pageNumber ?? 1}&pageSize=${pagination?.pageSize ?? 1}`);
    }
  }
    
  async function onSubmitHandler(searchFilter) {
    setSearchFilter(searchFilter);
    getSearchResults(searchFilter);
  }

  return (
    <Container maxWidth="lg" sx={{
      position: 'absolute', left: '50%', top: '50%',
      transform: 'translate(-50%, -50%)',
      marginTop: -5
    }}>
      <Grid container justifyContent="center">
        <Grid xs={12} md={8}>
          <Box sx={{ mb: 6 }}><img src={LOGO_URL} alt="Logo" /></Box>
          <SearchPanel onSubmitFunc={onSubmitHandler} />
          <Typography sx={{ mt: 6 }}>{DESCRIPTION_TEXT}</Typography>
          <Typography sx={{ marginLeft: '50%', marginRight: '50%'}}>{isLoading && <CircularProgress sx={{ mt: 3 }} />}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default App;