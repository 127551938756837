import { DateTime } from 'luxon';

import { AccordionWrapper } from './Accordions';
import { appSettings } from '../../settings/appSettings';
import Entry from './Entry';

const Events = ({ data }) => {
  if (!data) {
    return null;
  }
  
  return (
    <AccordionWrapper title={'Events'}>
      {data.map((event) => (
        <Entry 
          key={event.eventAction} 
          label={`${event.eventAction.charAt(0).toUpperCase() + event.eventAction.slice(1)}:`}
          value={DateTime.fromISO(event.eventDate).toFormat(appSettings.dateFormat.displayTime)}
        />
      ))}
    </AccordionWrapper>
  );
};

export default Events;