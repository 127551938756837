import { colors } from '../settings/colors'

export const borderStyle = {
  borderBottom: `1px solid ${colors.grey}`, 
  p: 2
};

const notAllowedCursorStyle = {
  cursor: 'not-allowed'
};

export const formInputFieldStyle = {
  backgroundColor: colors.white,
  'input:disabled': {
    '&::placeholder': {
      opacity: 1
    },
    ...notAllowedCursorStyle
  },
  'div.Mui-disabled': notAllowedCursorStyle,
  'textarea:disabled': notAllowedCursorStyle
};


export const defaultFieldProps = {
  rules: {},
  inputProps: {
    autoComplete: 'off'
  }
};
