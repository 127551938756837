import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useAtom } from 'jotai';

import { appSettings } from '../settings/appSettings';
import { formInputFieldStyle } from './DataTypes';
import FormSelect from './FormSelect';
import { searchTypeAtom } from '../atoms/searchAtoms';

const PLACEHOLDER_DICT = {
  'domains': 'Enter full or partial domain name...',
  'nameservers': 'Enter full or partial domain name of the name server...',
  'entity': 'Enter entity Handle ID...',
  'registrar': 'Enter IANA Number or Name...'
};

const SearchField = ({ id, label = '', showExternalLabel = false, props = {} }) => {
  const { control, formState: { errors } } = useFormContext();
  const rules = props.rules ?? {};
  const type = props.type ?? 'text';
  const isDisabled = props.isDisabled ?? false;
  const inputStyles = props.inputStyles ?? {};
  const [searchType,] = useAtom(searchTypeAtom);

  return (
    <FormControl fullWidth variant='standard'>
      { showExternalLabel && <Typography variant='body1'>{label}</Typography> }
      <Controller
        name={id}
        control={control}
        defaultValue={''}
        rules={rules}
        render={({ field }) => {
          return (
            <TextField
              type={type}
              size='small'
              value={field.value}
              label={label}
              onChange={(newType) => {
                field.onChange(newType);
              }}
              variant='outlined'
              sx={{ ...formInputFieldStyle, width: '100%', ...inputStyles }}
              error={!!errors[id]}
              disabled={isDisabled}
              placeholder={PLACEHOLDER_DICT[searchType]}
              InputProps={{ 
                autoComplete: 'off',
                startAdornment: (
                  <InputAdornment position="start" sx={{
                    'fieldset': {
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderBottom: 'none',
                      borderRadius: 0
                    },
                    width: '200px'
                  }}>
                    <FormSelect 
                      id={'type'} 
                      label={''} 
                      options={appSettings.types}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type='submit'>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
      />
    </FormControl>
  );
};

export default SearchField;
