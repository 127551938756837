import { Chip, Container, Box, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Unstable_Grid2';
import { Link } from "react-router-dom";
import { Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import { colors } from '../settings/colors';
import { searchAsync } from '../atoms/searchEventHandler';
import { searchFilterAtom } from '../atoms/searchAtoms';
import SearchPanel from '../components/SearchPanel';

const HandleInfo = ({ value }) => {
  const handle = value ? `Handle: ${value}` : 'No handle info available. ';
  
  return <Typography sx={{ textAlign: 'left', mt: '0!important' }}>{handle}</Typography>;
};

const StatusInfo = ({ status, variants }) => {
  if (status) {
    return (
      <Stack direction={'row'} spacing={2} sx={{ mt: '4px', ml: 1 }}>
        <Typography>Status:</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', rowGap: 2, ml: '5px!important' }}>
          {status.map((s) => (
            <Chip 
              key={s} 
              label={s} 
              size='small'
              className='rdap-search-status-chip'
              sx={{ ml: 1, backgroundColor: colors.lightGreen, color: colors.white }} 
            />
          ))}
        </Box>
      </Stack>
    );
  }

  if (variants && variants[0] && variants[0]['relations'] && variants[0]['relations'][0]) {
    return (
      <Stack direction={'row'} spacing={2} sx={{ mt: '4px', ml: 1 }}>
        <Typography>Status:</Typography>
        <Chip 
            key={'Restricted Registration'} 
            label={'Restricted Registration'} 
            size='small'
            className='rdap-search-variant-chip'
            sx={{ ml: 1, backgroundColor: colors.darkGrey, color: colors.white }} 
          />
      </Stack>
    );
  }

  return null;
};

const Summary = ({ data }) => {
  return (
    <Stack direction='column' spacing={2}>
      <StatusInfo status={data.status} variants={data.variants} />
    </Stack>
  );
};

const SearchResults = ({ searchResults, type }) => {
  return (
    searchResults.map((result, index) => {
      const key = result.ldhName ?? result.handle;

      return (
        <Stack direction='column' spacing={2} key={`${key}-${index}`} sx={{ my: 4 }}>
          <Link to={`/resource?keyword=${key}&type=${type}`} style={{ display: 'flex' }}>
            <Typography variant='h5'>{key}</Typography>
          </Link>
          <Summary key={`search-result-${index}`} data={result} />
        </Stack>
    )})
  );
};

const Search = () => {
  const [searchFilter, setSearchFilter] = useAtom(searchFilterAtom);
  const [searchResults, setSearchResults] = useState({ results: [], pagination: {} });
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  async function getSearchResults(param, pageNumber = 1, pageSize = 10) {
    setIsLoading(true);

    const searchResults = await searchAsync(param, pageNumber, pageSize);
    const results = searchResults.results;
    const pagination = searchResults.pagination;

    setSearchResults(searchResults);
    setIsLoading(false);

    if (results && results.length === 1) {
      const result = results[0];
      const key = result.ldhName ?? result.handle;

      navigate(`/resource?keyword=${key}&type=${param.type}&bth=true`);
    } else {
      navigate(`/search?pageNumber=${pagination?.pageNumber ?? 1}&pageSize=${pagination?.pageSize ?? 1}`);
    }
  }

  useEffect(() => {
    if (searchFilter.keyword) {
      getSearchResults(searchFilter, searchParams.get('pageNumber'), searchParams.get('pageSize'));
    }
  }, []);

  async function onSubmitHandler(searchParam) {
    setSearchFilter(searchParam);
    getSearchResults(searchParam);
  }

  const handleChangePage = (event, newPageNumber) => {
    getSearchResults(searchFilter, newPageNumber, searchResults.pagination?.pageSize ?? 1);
    navigate(`/search?pageNumber=${newPageNumber}&pageSize=${searchResults.pagination?.pageSize ?? 1}`);
  };

  return (
    <Container id='rdap-search-results-container' maxWidth="lg" sx={{ marginTop: 10 }}>
      <Grid container justifyContent="center">
        <Grid xs={12} md={8}>
          <SearchPanel onSubmitFunc={onSubmitHandler} />
        </Grid>
        <Grid xs={12} md={8}>
        <Typography sx={{ marginLeft: '50%', marginRight: '50%'}}>{isLoading && <CircularProgress sx={{ mt: 3 }} />}</Typography>
          {isLoading === false && searchResults.pagination && searchResults.pagination?.totalCount > 10 && 
            <Box>
              <Typography variant='body1' sx={{ textAlign: 'left', mt: 2 }}>{`Found ${searchResults.pagination?.totalCount ?? 0} results`}</Typography>
              <SearchResults searchResults={searchResults.results} type={searchFilter.type} />
              <Pagination
                count={Math.ceil(searchResults.pagination?.totalCount / searchResults.pagination?.pageSize)}
                page={searchResults.pagination?.pageNumber}
                onChange={handleChangePage}
                color={"primary"}
                sx={{ display: 'flex', justifyContent: 'center', my: 2 }}
              />
            </Box>
          }
          {isLoading === false && searchResults.pagination && searchResults.pagination?.totalCount <= 10 && 
            <Box>
              <Typography variant='body1' sx={{ textAlign: 'left', mt: 2 }}>{`Found ${searchResults.pagination?.totalCount ?? 0} results`}</Typography>
              <SearchResults searchResults={searchResults.results} type={searchFilter.type} />
            </Box>
          }
          {isLoading === false && (!searchResults.pagination) && 
            <Box>
              <Typography variant='body1' sx={{ textAlign: 'left', mt: 2 }}>{`Found ${searchResults.pagination?.totalCount ?? 0} results`}</Typography>
            </Box>
          }
        </Grid>
      </Grid>
    </Container>
  );
};

export default Search;
